<template>
	<div class="upload-thumb">
		<el-button @click="dialogVisible = true" :disabled="disabled" type="primary" size="mini">{{model ? "重新" : ""}}上傳縮圖</el-button>
		<template v-if="model">
			<el-button @click="$emit('change', '')" :disabled="disabled" type="danger" size="mini">移除縮圖</el-button>
		</template>
		<br>
		<br>
		<ratio-frame :square="square">
			<el-image :src="model" :preview-src-list="[model]" fit="cover">
				<div slot="error" class="center" style="width:100%; height:100%; background:#eee;">
					<i class="el-icon-picture-outline" />
				</div>
			</el-image>
		</ratio-frame>
		<small v-if="model"><b>點擊圖片可以預覽</b></small>

		<template v-if="!disabled">
			<el-dialog :visible.sync="dialogVisible">
				<div class="center">
					<el-upload action="" :on-change="handleChange" accept="image/*" :auto-upload="false" :show-file-list="false" drag>
						<i class="el-icon-upload" />
						<div class="el-upload__text">將圖片拖到此處，或<em>點擊上傳</em></div>
						<div class="el-upload__tip" slot="tip">最大不超過5MB</div>
					</el-upload>
				</div>
			</el-dialog>
		</template>
	</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
	components: {
		RatioFrame: () => import("@/components/ratio-frame")
	},
	props: {
		"media-folder": String,
		model: {
			type: String,
			default: ""
		},
		square: Boolean,
		disabled: Boolean
	},
	model: {
		prop: "model",
		event: "change"
	},
	data() {
		return {
			dialogVisible: false
		}
	},
	methods: {
		...mapActions("upload", ["_uploadImage"]),
		async handleChange({ raw }, fileList) {
			const data = new FormData();
			data.append("file", raw);
			const { url } = await this._uploadImage({
				data,
				folder: this.mediaFolder
					|| this.$route.path
						.split("/")
						.filter(item => item && item != "manager")
						.join("-"),
			});
			this.$emit("change", url);
			this.dialogVisible = false;
		},
	}
}
</script>